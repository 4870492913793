<template>
    <div class="tabCard">
        <el-tabs v-model="activeName">
            <el-tab-pane label="私海分组" name="1">
                <div style="display: flex;justify-content: space-between;">
                    <el-button v-if="isAdmin" @click="toNewPrivate" size="small" type="primary" icon="el-icon-plus" style="margin-bottom:20px;">新建</el-button>
                    <span v-else class="setTipText">非管理员无法设置操作,请联系管理员操作</span>
                    <div style="color: #F56C6C;font-size: 14px;">tip:客户释放后2天内不可重新加入我的客户</div>
                </div>
                <div style="padding:5px 0;"></div>
                <el-table :data="PrivateGroupOption" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}" :row-style="rowStyle">
                    <el-table-column prop="name" label="私海分组名称">
                        <template slot-scope="scope">
                            <div class="nameStyle">
                                <span>{{scope.row.name}}</span>
                                <span v-show="scope.row.isSystem" class="oneItem">系统</span>
                                <span v-if="scope.row.isDefault" class="oneItem">默认</span>
                                <span v-else class="hoverShow" @click="setDefault(scope.row,1)">
                                    <i class="el-icon-success" style="color:#F96B0D;"></i>
                                    <span style="color:#999;">设为默认</span>
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="释放天数" width="200">
                        <template slot-scope="scope">
                            <span v-if="scope.row.day==-1">永不释放</span>
                            <span v-else>{{ scope.row.day }} 天释放</span>
                            <span style="padding:0 5px;"></span>
                            <div v-if="isAdmin" class="handleEditBtn" @click="toSetDay(scope.row)">设置</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="200" v-if="isAdmin">
                        <template slot-scope="scope">
                            <div v-show="!scope.row.isSystem && !scope.row.isDefault">
                                <div class="handleEditBtn" @click="toEditPrivate(scope.row)">编辑</div>
                                <div class="handleDelBtn" @click="toDelPrivate(scope.row)">删除</div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="公海分组" name="2">
                <el-button v-if="isAdmin" @click="toNewPublic" size="small" type="primary" icon="el-icon-plus" style="margin-bottom:20px;">新建</el-button>
                <span v-else class="setTipText">非管理员无法设置操作,请联系管理员操作</span>
                <div style="padding:5px 0;"></div>
                <el-table :data="PublicGroupOption" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}" :row-style="rowStyle">
                    <el-table-column prop="name" label="公海分组名称">
                        <template slot-scope="scope">
                            <div class="nameStyle">
                                <span>{{scope.row.name}}</span>
                                <span v-show="scope.row.isSystem" class="oneItem">系统</span>
                                <span v-if="scope.row.isDefault" class="oneItem">默认</span>
                                <span v-else class="hoverShow" @click="setDefault(scope.row,2)">
                                    <i class="el-icon-success" style="color:#F96B0D;"></i>
                                    <span style="color:#999;">设为默认</span>
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="showChengyuan" label="查看权限"></el-table-column>
                    <el-table-column label="操作" width="200" v-if="isAdmin">
                        <template slot-scope="scope">
                            <div v-show="!scope.row.isSystem && !scope.row.isDefault">
                                <div class="handleEditBtn" @click="toEditPublic(scope.row)">编辑</div>
                                <div class="handleDelBtn" @click="toDelPublic(scope.row)">删除</div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="客户标签" name="3">
                <el-button @click="toNewTag" size="small" type="primary" icon="el-icon-plus" style="margin-bottom:20px;">新建</el-button>
                <el-table :data="TagOption" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}" :row-style="rowStyle">
                    <el-table-column prop="name" label="客户标签名称">
                        <template slot-scope="scope">
                            <el-tag :color="scope.row.color" effect="dark" size="mini">{{scope.row.name}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="200">
                        <template slot-scope="scope">
                            <div class="handleEditBtn" @click="toEditTag(scope.row)">编辑</div>
                            <div class="handleDelBtn" @click="toDelTag(scope.row)">删除</div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="公司来源" name="4">
                <el-button v-if="isAdmin" @click="toNewSource" size="small" type="primary" icon="el-icon-plus" style="margin-bottom:20px;">新建</el-button>
                <span v-else class="setTipText">非管理员无法设置操作,请联系管理员操作</span>
                <div style="padding:5px 0;"></div>
                <el-table :data="SourceOption" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}" :row-style="rowStyle">
                    <el-table-column prop="name" label="公司来源名称">
                        <template slot-scope="scope">
                            <div class="nameStyle">
                                <span>{{scope.row.name}}</span>
                                <span v-show="scope.row.isSystem" class="oneItem">系统</span>
                                <span v-if="scope.row.isDefault" class="oneItem">默认</span>
                                <span v-else class="hoverShow" @click="setDefault(scope.row,3)">
                                    <i class="el-icon-success" style="color:#F96B0D;"></i>
                                    <span style="color:#999;">设为默认</span>
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="200" v-if="isAdmin">
                        <template slot-scope="scope">
                            <div v-show="!scope.row.isSystem && !scope.row.isDefault">
                                <div class="handleEditBtn" @click="toEditSource(scope.row)">编辑</div>
                                <div class="handleDelBtn" @click="toDelSource(scope.row)">删除</div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="公司状态" name="5">
                <el-button v-if="isAdmin" @click="toNewStatu" size="small" type="primary" icon="el-icon-plus" style="margin-bottom:20px;">新建</el-button>
                <span v-else class="setTipText">非管理员无法设置操作,请联系管理员操作</span>
                <div style="padding:5px 0;"></div>
                <el-table :data="StatuOption" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}" :row-style="rowStyle">
                    <el-table-column prop="name" label="公司状态名称">
                        <template slot-scope="scope">
                            <div class="nameStyle">
                                <span>{{scope.row.name}}</span>
                                <span v-show="scope.row.isSystem" class="oneItem">系统</span>
                                <span v-if="scope.row.isDefault" class="oneItem">默认</span>
                                <span v-else class="hoverShow" @click="setDefault(scope.row,4)">
                                    <i class="el-icon-success" style="color:#F96B0D;"></i>
                                    <span style="color:#999;">设为默认</span>
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="200" v-if="isAdmin">
                        <template slot-scope="scope">
                            <div v-show="!scope.row.isSystem && !scope.row.isDefault">
                                <div class="handleEditBtn" @click="toEditStatu(scope.row)">编辑</div>
                                <div class="handleDelBtn" @click="toDelStatu(scope.row)">删除</div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <!-- <el-tab-pane label="客户回收站" name="6">
                <div style="margin-bottom:20px;">
                    <el-input v-model="customerName" style="width:300px;" clearable size="small" placeholder="公司名称"></el-input>
                    <el-button type="primary" size="small" @click="pageChange(1)">搜索</el-button>
                </div>
                <el-table :data="recycleList" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}" :row-style="rowStyle">
                    <el-table-column prop="customerName" label="公司名称"></el-table-column>
                    <el-table-column prop="createTime" label="回收时间"></el-table-column>
                    <el-table-column prop="reason" label="回收原因"></el-table-column>
                </el-table>
                <el-pagination background layout="total,prev,pager,next" :total="totalItems" style="float:right;margin:15px 20px;" :current-page.sync="pageIndex" @current-change="pageChange"></el-pagination>
            </el-tab-pane> -->
            <el-tab-pane label="客户字段" name="7">
                <FieldSet :type="0" />
            </el-tab-pane>
            <el-tab-pane label="其他" name="8">
                <el-checkbox v-model="sysConfig.isShowCollisionRisk" @change="setCollisionRisk">撞单风险提示</el-checkbox>
                <p style="height: 20px;"></p>
                <!-- 管理员新增3个设置权限(2024-05-31) -->
                <div v-if="isAdmin">
                    <el-checkbox v-model="comConfig.isDeleteFollowRecords" @change="setCompanyConfig">客户动态可删除(不含系统动态)</el-checkbox>
                    <p style="height: 20px;"></p>
                    <el-checkbox v-model="comConfig.isQuitFollowRecordsShow" @change="setCompanyConfig">是否展示离职员工动态</el-checkbox>
                    <p style="height: 20px;"></p>
                    <div style="display: flex;align-items: center;">
                        <span style="font-size: 14px;color:#6a6a6a;">客户动态仅本人可见(选账号)：</span>
                        <el-select v-model="comConfig.followRecordsOnlyUsers" size="small" filterable multiple clearable style="width:300px;">
                            <el-option v-for="item in staffOption" :key="item.key" :label="item.name" :value="item.key"></el-option>
                        </el-select>
                        <el-button size="small" @click="setCompanyConfig">保 存</el-button>
                    </div>
                </div>
                <!-- 管理员新增客户字段是否必填设置(2024-10-12) -->
                <div v-if="isAdmin" style="margin-top: 20px;padding-top: 10px;border: 1px solid #c9c9c9;">
                    <el-button size="small" @click="saveCustomerField" style="float: right;margin-right: 10px;">保存设置</el-button>
                    <el-table :data="comConfig.customerFieldSettings" style="width: 100%">
                        <el-table-column prop="columnCn" label="字段名称"></el-table-column>
                        <el-table-column prop="required" label="是否必填">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.required"></el-switch>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>


            <!-- <el-tab-pane label="商机阶段">
                <el-button @click="toNewStage" size="small" type="primary" icon="el-icon-plus" style="margin-bottom:20px;">新建</el-button>
                <el-table :data="StageOption" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}" :row-style="rowStyle">
                    <el-table-column prop="name" label="商机阶段名称">
                        <template slot-scope="scope">
                            <div class="nameStyle">
                                <span>{{scope.row.name}}</span>
                                <span v-show="scope.row.isSystem" class="oneItem">系统</span>
                                <span v-if="scope.row.isDefault" class="oneItem">默认</span>
                                <span v-else class="hoverShow" @click="setDefault(scope.row,5)">
                                    <i class="el-icon-success" style="color:#F96B0D;"></i>
                                    <span style="color:#999;">设为默认</span>
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="200">
                        <template slot-scope="scope">
                            <div v-show="!scope.row.isSystem && !scope.row.isDefault">
                                <div class="handleEditBtn" @click="toEditStage(scope.row)">编辑</div>
                                <div class="handleDelBtn" @click="toDelStage(scope.row)">删除</div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane> -->
        </el-tabs>

        <!-- 私海分组配置 -->
        <el-dialog title="新建私海分组" :visible.sync="addVisibleOne" width="500px">
            <el-input v-model="addForm.name" size="small" placeholder="私海分组名称"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="newPrivateSubmit">确 认</el-button>
                <el-button size="small" @click="addVisibleOne = false">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog title="编辑私海分组" :visible.sync="editVisibleOne" width="500px">
            <el-input v-model="editForm.name" size="small" placeholder="私海分组名称"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="editPrivateSubmit">确 认</el-button>
                <el-button size="small" @click="editVisibleOne = false">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog title="删除私海分组" :visible.sync="delVisibleOne" width="500px">
            <div style="margin-bottom:10px">
                删除该私海分组：<strong style='color:var(--themeColor);'>{{delName}}</strong>，该分组内容转移至：
            </div>
            <el-select v-model="transferKey" placeholder="请选择" size="small">
                <el-option v-for="item in PrivateGroupOption" :key="item.key" :label="item.name" :value="item.key"
                 :disabled="item.key == delKey"></el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="delPrivateSubmit">确 认</el-button>
                <el-button size="small" @click="delVisibleOne = false">取 消</el-button>
            </span>
        </el-dialog>

        <!-- 公海分组配置 -->
        <el-dialog title="新建公海分组" :visible.sync="addVisibleTwo" width="500px">
            <el-input v-model="addForm.name" size="small" placeholder="公海分组名称"></el-input>
            <div style="margin: 10px 0;">
                勾选成员不可见该分组：
            </div>
            <el-select v-model="addForm.ukeys" size="small" filterable multiple clearable style="width:100%;">
                <el-option v-for="item in staffOption" :key="item.key" :label="item.name" :value="item.key"></el-option>
            </el-select>
            <div style="font-size: 14px;color: #858585;margin-top: 10px;">
                *tip：不选成员即表示全部成员都可以查看该分组!
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="newPublicSubmit">确 认</el-button>
                <el-button size="small" @click="addVisibleTwo = false">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog title="编辑公海分组" :visible.sync="editVisibleTwo" width="500px">
            <el-input v-model="editForm.name" size="small" placeholder="公海分组名称"></el-input>
            <div style="margin: 10px 0;">
                勾选成员不可见该分组：
            </div>
            <el-select v-model="editForm.ukeys" size="small" filterable multiple clearable style="width:100%;">
                <el-option v-for="item in staffOption" :key="item.key" :label="item.name" :value="item.key"></el-option>
            </el-select>
            <div style="font-size: 14px;color: #858585;margin-top: 10px;">
                *tip：不选成员即表示全部成员都可以查看该分组!
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="editPublicSubmit">确 认</el-button>
                <el-button size="small" @click="editVisibleTwo = false">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog title="删除公海分组" :visible.sync="delVisibleTwo" width="500px">
            <div style="margin-bottom:10px">
                删除该公海分组：<strong style='color:var(--themeColor);'>{{delName}}</strong>，该分组内容转移至：
            </div>
            <el-select v-model="transferKey" placeholder="请选择" size="small">
                <el-option v-for="item in PublicGroupOption" :key="item.key" :label="item.name" :value="item.key"
                 :disabled="item.key == delKey"></el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="delPublicSubmit">确 认</el-button>
                <el-button size="small" @click="delVisibleTwo = false">取 消</el-button>
            </span>
        </el-dialog>

        <!-- 客户标签配置 -->
        <el-dialog title="新建客户标签" :visible.sync="addVisibleThree" width="500px">
            <div style="display: flex;">
                <el-color-picker v-model="addForm.color" :predefine="predefineColors" size="small" @active-change="(color) => addForm.color = color"></el-color-picker>
                <el-input v-model="addForm.name" size="small" placeholder="客户标签名称"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="newTagSubmit">确 认</el-button>
                <el-button size="small" @click="addVisibleThree = false">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog title="编辑客户标签" :visible.sync="editVisibleThree" width="500px">
            <div style="display: flex;">
                <el-color-picker v-model="editForm.color" :predefine="predefineColors" size="small" @active-change="(color) => editForm.color = color"></el-color-picker>
                <el-input v-model="editForm.name" size="small" placeholder="客户标签名称"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="editTagSubmit">确 认</el-button>
                <el-button size="small" @click="editVisibleThree = false">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog title="删除客户标签" :visible.sync="delVisibleThree" width="500px">
            <div style="margin-bottom:10px">
                删除该客户标签：<strong style='color:var(--themeColor);'>{{delName}}</strong>，该分组内容转移至：
            </div>
            <el-select v-model="transferKey" placeholder="请选择" size="small">
                <el-option v-for="item in TagOption" :key="item.key" :label="item.name" :value="item.key"
                 :disabled="item.key == delKey"></el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="noShiftDelTag">不转移直接删除</el-button>
                <el-button size="small" type="primary" @click="delTagSubmit">确 认</el-button>
                <el-button size="small" @click="delVisibleThree = false">取 消</el-button>
            </span>
        </el-dialog>

        <!-- 公司来源配置 -->
        <el-dialog title="新建公司来源" :visible.sync="addVisibleFour" width="500px">
            <el-input v-model="addForm.name" size="small" placeholder="公司来源名称"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="newSourceSubmit">确 认</el-button>
                <el-button size="small" @click="addVisibleFour = false">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog title="编辑公司来源" :visible.sync="editVisibleFour" width="500px">
            <el-input v-model="editForm.name" size="small" placeholder="公司来源名称"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="editSourceSubmit">确 认</el-button>
                <el-button size="small" @click="editVisibleFour = false">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog title="删除公司来源" :visible.sync="delVisibleFour" width="500px">
            <div style="margin-bottom:10px">
                删除该公司来源：<strong style='color:var(--themeColor);'>{{delName}}</strong>，该分组内容转移至：
            </div>
            <el-select v-model="transferKey" placeholder="请选择" size="small">
                <el-option v-for="item in SourceOption" :key="item.key" :label="item.name" :value="item.key"
                 :disabled="item.key == delKey"></el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="delSourceSubmit">确 认</el-button>
                <el-button size="small" @click="delVisibleFour = false">取 消</el-button>
            </span>
        </el-dialog>

        <!-- 公司状态配置 -->
        <el-dialog title="新建公司状态" :visible.sync="addVisibleFive" width="500px">
            <el-input v-model="addForm.name" size="small" placeholder="公司状态名称"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="newStatuSubmit">确 认</el-button>
                <el-button size="small" @click="addVisibleFive = false">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog title="编辑公司状态" :visible.sync="editVisibleFive" width="500px">
            <el-input v-model="editForm.name" size="small" placeholder="公司状态名称"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="editStatuSubmit">确 认</el-button>
                <el-button size="small" @click="editVisibleFive = false">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog title="删除公司状态" :visible.sync="delVisibleFive" width="500px">
            <div style="margin-bottom:10px">
                删除该公司状态：<strong style='color:var(--themeColor);'>{{delName}}</strong>，该分组内容转移至：
            </div>
            <el-select v-model="transferKey" placeholder="请选择" size="small">
                <el-option v-for="item in StatuOption" :key="item.key" :label="item.name" :value="item.key"
                 :disabled="item.key == delKey"></el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="delStatuSubmit">确 认</el-button>
                <el-button size="small" @click="delVisibleFive = false">取 消</el-button>
            </span>
        </el-dialog>

        <!-- 商机阶段配置 -->
        <el-dialog title="新建商机阶段" :visible.sync="addVisibleSix" width="500px">
            <el-input v-model="addForm.name" size="small" placeholder="商机阶段名称"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="newStageSubmit">确 认</el-button>
                <el-button size="small" @click="addVisibleSix = false">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog title="编辑商机阶段" :visible.sync="editVisibleSix" width="500px">
            <el-input v-model="editForm.name" size="small" placeholder="商机阶段名称"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="editStageSubmit">确 认</el-button>
                <el-button size="small" @click="editVisibleSix = false">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog title="删除商机阶段" :visible.sync="delVisibleSix" width="500px">
            <div style="margin-bottom:10px">
                删除该商机阶段：<strong style='color:var(--themeColor);'>{{delName}}</strong>，该分组内容转移至：
            </div>
            <el-select v-model="transferKey" placeholder="请选择" size="small">
                <el-option v-for="item in StageOption" :key="item.key" :label="item.name" :value="item.key"
                 :disabled="item.key == delKey"></el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="delStageSubmit">确 认</el-button>
                <el-button size="small" @click="delVisibleSix = false">取 消</el-button>
            </span>
        </el-dialog>


        <!-- 设置释放天数 -->
        <el-dialog title="设置释放天数" :visible.sync="setDayVisible" width="500px">
            <div>
                <el-input-number v-model="setDayNumber" :min="1" :max="365" size="small"></el-input-number>
                <span style="padding-left: 10px;">天释放</span>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" plain @click="setDayFinish(-1)">永不释放</el-button>
                <el-button size="small" type="primary" @click="setDayFinish(setDayNumber)">确 认</el-button>
                <el-button size="small" @click="setDayVisible = false">取 消</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import FieldSet from "./ComSet/FieldSet.vue";
export default {
    components: { FieldSet },
    data() {
        return {
            activeName: "1",
            isAdmin: false,
            addForm: {},    //新建表单数据
            editForm: {},   //编辑表单数据
            delKey: "",
            delName: "",
            transferKey: "",
            //私海分组配置
            PrivateGroupOption: [],
            addVisibleOne: false,
            editVisibleOne: false,
            delVisibleOne: false,
            //公海分组配置
            PublicGroupOption: [],
            addVisibleTwo: false,
            editVisibleTwo: false,
            delVisibleTwo: false,
            //客户标签配置
            TagOption: [],
            addVisibleThree: false,
            editVisibleThree: false,
            delVisibleThree: false,
            predefineColors: ["#EA9426","#f759ab","#9254de","#597ef7","#40a9ff","#36cfc9","#73d13d","#ffec3d","#ff4d4f","#ff7a45"],
            //公司来源配置
            SourceOption: [],
            addVisibleFour: false,
            editVisibleFour: false,
            delVisibleFour: false,
            //公司状态配置
            StatuOption: [],
            addVisibleFive: false,
            editVisibleFive: false,
            delVisibleFive: false,
            //商机阶段配置
            StageOption: [],
            addVisibleSix: false,
            editVisibleSix: false,
            delVisibleSix: false,
            //客户回收站
            customerName: "",
            recycleList: [],
            totalItems: 0,
            pageIndex: 1,
            //设置释放天数
            setDayVisible: false,
            setDayKey: "",
            setDayNumber: 30,
            //撞单风险提示
            sysConfig: localStorage.sysConfig ? JSON.parse(localStorage.sysConfig) : {},
            staffOption: [],
            comConfig: localStorage.comConfig ? JSON.parse(localStorage.comConfig) : {},
        };
    },
    methods: {
        rowStyle({rowIndex}){
            // 表格隔行换色
            if(rowIndex % 2 == 1){
                return {'background-color':'#F9FAFC'}
            }
        },

        // 设为默认     1私海分组 2公海分组 3公司来源 4公司状态 5商机阶段
        setDefault(row,type){
            if(!this.isAdmin){
                this.$message({ message: '非管理员无法操作,请联系管理员操作', type: 'warning'});
                return false;
            }
            this.$axios.post(`${this.$customerUrl}/v1/Config/Default`,{
                key: row.key,
                type,
            }).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ type: 'success', message: '设置成功!'});
                    if(type == 1){ this.getPrivateGroup() }
                    if(type == 2){ this.getPublicGroup() }
                    if(type == 3){ this.getCustomerSource() }
                    if(type == 4){ this.getCustomerStatu() }
                    if(type == 5){ this.getStage() }
                }
            });
        },

        // 私海分组配置
        getPrivateGroup(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/PrivateGroup`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.PrivateGroupOption = data;
                }
            });
        },
        toNewPrivate(){
            this.addVisibleOne = true;
            this.addForm = {
                key: "",
                name: "",
            };
        },
        newPrivateSubmit(){
            if(!this.addForm.name){
                this.$message({ message: '请输入私海分组名称', type: 'warning'});
                return false;
            }
            this.$axios.post(`${this.$customerUrl}/v1/Config/PrivateGroup`,this.addForm).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.addVisibleOne = false;
                    this.$message({ message: '新建成功', type: 'success'});
                    this.getPrivateGroup();
                }
            });
        },
        toEditPrivate(row){
            this.editVisibleOne = true;
            this.editForm = {...row};
        },
        editPrivateSubmit(){
            if(!this.editForm.name){
                this.$message({ message: '请输入私海分组名称', type: 'warning'});
                return false;
            }
            this.$axios.post(`${this.$customerUrl}/v1/Config/PrivateGroup`,this.editForm).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.editVisibleOne = false;
                    this.$message({ message: '编辑成功', type: 'success'});
                    this.getPrivateGroup();
                }
            });
        },
        toDelPrivate(row){
            this.delVisibleOne = true;
            this.delKey = row.key;
            this.delName = row.name;
            this.transferKey = "";
        },
        delPrivateSubmit(){
            if(!this.transferKey){
                this.$message({ message: '请选择转移的对象', type: 'warning'});
                return false;
            }
            this.$axios.delete(`${this.$customerUrl}/v1/Config/PrivateGroup?key=${this.delKey}&transferKey=${this.transferKey}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.delVisibleOne = false;
                    this.$message({ type: 'success', message: '删除成功!'});
                    this.getPrivateGroup();
                }
            });
        },
        //设置释放天数
        toSetDay(row){
            this.setDayVisible = true;
            this.setDayKey = row.key;
            this.setDayNumber = row.day == -1 ? 30 : row.day;
        },
        setDayFinish(day){
            this.$axios.get(`${this.$customerUrl}/v1/Config/SetGroupDay?groupKey=${this.setDayKey}&day=${day}`).then((res) => {
                const { isSuccess } = res;
                this.setDayVisible = false;
                if (isSuccess) {
                    this.$message({ message: '设置成功', type: 'success'});
                    this.getPrivateGroup();
                }
            });
        },

        //公海分组配置
        async getPublicGroup(){
            //获取所有成员集合
            await this.$axios.get(`${this.$personnelUrl}/Organization/FindStaffBox`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.staffOption = data;
                }
            })
            this.$axios.get(`${this.$customerUrl}/v1/Config/PublicGroup`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    data.forEach(element => {
                        element.showChengyuan = "";
                        if(element.ukeys.length==0){
                            element.showChengyuan = "所有成员 可见";
                        }else{
                            this.staffOption.forEach(item =>{
                                if(element.ukeys.includes(item.key)){
                                    element.showChengyuan = element.showChengyuan + item.name + " ";
                                }
                            })
                            element.showChengyuan = element.showChengyuan + "不可见";
                        }
                    });
                    this.PublicGroupOption = data;
                }
            });
        },
        toNewPublic(){
            this.addVisibleTwo = true;
            this.addForm = {
                key: "",
                name: "",
                ukeys: [],
            };
        },
        newPublicSubmit(){
            if(!this.addForm.name){
                this.$message({ message: '请输入公海分组名称', type: 'warning'});
                return false;
            }
            this.$axios.post(`${this.$customerUrl}/v1/Config/PublicGroup`,this.addForm).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.addVisibleTwo = false;
                    this.$message({ message: '新建成功', type: 'success'});
                    this.getPublicGroup();
                }
            });
        },
        toEditPublic(row){
            this.editVisibleTwo = true;
            this.editForm = {...row};
        },
        editPublicSubmit(){
            if(!this.editForm.name){
                this.$message({ message: '请输入公海分组名称', type: 'warning'});
                return false;
            }
            this.$axios.post(`${this.$customerUrl}/v1/Config/PublicGroup`,this.editForm).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.editVisibleTwo = false;
                    this.$message({ message: '编辑成功', type: 'success'});
                    this.getPublicGroup();
                }
            });
        },
        toDelPublic(row){
            this.delVisibleTwo = true;
            this.delKey = row.key;
            this.delName = row.name;
            this.transferKey = "";
        },
        delPublicSubmit(){
            if(!this.transferKey){
                this.$message({ message: '请选择转移的对象', type: 'warning'});
                return false;
            }
            this.$axios.delete(`${this.$customerUrl}/v1/Config/PublicGroup?key=${this.delKey}&transferKey=${this.transferKey}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.delVisibleTwo = false;
                    this.$message({ type: 'success', message: '删除成功!'});
                    this.getPublicGroup();
                }
            });
        },

        //客户标签配置
        getCustomerTag(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/Tag`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.TagOption = data;
                }
            });
        },
        toNewTag(){
            this.addVisibleThree = true;
            this.addForm = {
                key: "",
                name: "",
                color: "#EA9426",
            };
        },
        newTagSubmit(){
            if(!this.addForm.name){
                this.$message({ message: '请输入客户标签名称', type: 'warning'});
                return false;
            }
            this.$axios.post(`${this.$customerUrl}/v1/Config/Tag`,this.addForm).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.addVisibleThree = false;
                    this.$message({ message: '新建成功', type: 'success'});
                    this.getCustomerTag();
                }
            });
        },
        toEditTag(row){
            this.editVisibleThree = true;
            this.editForm = {...row};
        },
        editTagSubmit(){
            if(!this.editForm.name){
                this.$message({ message: '请输入客户标签名称', type: 'warning'});
                return false;
            }
            this.$axios.post(`${this.$customerUrl}/v1/Config/Tag`,this.editForm).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.editVisibleThree = false;
                    this.$message({ message: '编辑成功', type: 'success'});
                    this.getCustomerTag();
                }
            });
        },
        toDelTag(row){
            this.delVisibleThree = true;
            this.delKey = row.key;
            this.delName = row.name;
            this.transferKey = "";
        },
        noShiftDelTag(){
            this.$axios.delete(`${this.$customerUrl}/v1/Config/Tag?key=${this.delKey}&transferKey=${this.transferKey}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.delVisibleThree = false;
                    this.$message({ type: 'success', message: '删除成功!'});
                    this.getCustomerTag();
                }
            });
        },
        delTagSubmit(){
            if(!this.transferKey){
                this.$message({ message: '请选择转移的对象', type: 'warning'});
                return false;
            }
            this.$axios.delete(`${this.$customerUrl}/v1/Config/Tag?key=${this.delKey}&transferKey=${this.transferKey}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.delVisibleThree = false;
                    this.$message({ type: 'success', message: '删除成功!'});
                    this.getCustomerTag();
                }
            });
        },

        //公司来源配置
        getCustomerSource(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/Source`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.SourceOption = data;
                }
            });
        },
        toNewSource(){
            this.addVisibleFour = true;
            this.addForm = {
                key: "",
                name: "",
            };
        },
        newSourceSubmit(){
            if(!this.addForm.name){
                this.$message({ message: '请输入公司来源名称', type: 'warning'});
                return false;
            }
            this.$axios.post(`${this.$customerUrl}/v1/Config/Source`,this.addForm).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.addVisibleFour = false;
                    this.$message({ message: '新建成功', type: 'success'});
                    this.getCustomerSource();
                }
            });
        },
        toEditSource(row){
            this.editVisibleFour = true;
            this.editForm = {...row};
        },
        editSourceSubmit(){
            if(!this.editForm.name){
                this.$message({ message: '请输入公司来源名称', type: 'warning'});
                return false;
            }
            this.$axios.post(`${this.$customerUrl}/v1/Config/Source`,this.editForm).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.editVisibleFour = false;
                    this.$message({ message: '编辑成功', type: 'success'});
                    this.getCustomerSource();
                }
            });
        },
        toDelSource(row){
            this.delVisibleFour = true;
            this.delKey = row.key;
            this.delName = row.name;
            this.transferKey = "";
        },
        delSourceSubmit(){
            if(!this.transferKey){
                this.$message({ message: '请选择转移的对象', type: 'warning'});
                return false;
            }
            this.$axios.delete(`${this.$customerUrl}/v1/Config/Source?key=${this.delKey}&transferKey=${this.transferKey}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.delVisibleFour = false;
                    this.$message({ type: 'success', message: '删除成功!'});
                    this.getCustomerSource();
                }
            });
        },

        //公司状态配置
        getCustomerStatu(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/Statu`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.StatuOption = data;
                }
            });
        },
        toNewStatu(){
            this.addVisibleFive = true;
            this.addForm = {
                key: "",
                name: "",
                color: "",
            };
        },
        newStatuSubmit(){
            if(!this.addForm.name){
                this.$message({ message: '请输入公司状态名称', type: 'warning'});
                return false;
            }
            this.$axios.post(`${this.$customerUrl}/v1/Config/Statu`,this.addForm).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.addVisibleFive = false;
                    this.$message({ message: '新建成功', type: 'success'});
                    this.getCustomerStatu();
                }
            });
        },
        toEditStatu(row){
            this.editVisibleFive = true;
            this.editForm = {...row};
        },
        editStatuSubmit(){
            if(!this.editForm.name){
                this.$message({ message: '请输入公司状态名称', type: 'warning'});
                return false;
            }
            this.$axios.post(`${this.$customerUrl}/v1/Config/Statu`,this.editForm).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.editVisibleFive = false;
                    this.$message({ message: '编辑成功', type: 'success'});
                    this.getCustomerStatu();
                }
            });
        },
        toDelStatu(row){
            this.delVisibleFive = true;
            this.delKey = row.key;
            this.delName = row.name;
            this.transferKey = "";
        },
        delStatuSubmit(){
            if(!this.transferKey){
                this.$message({ message: '请选择转移的对象', type: 'warning'});
                return false;
            }
            this.$axios.delete(`${this.$customerUrl}/v1/Config/Statu?key=${this.delKey}&transferKey=${this.transferKey}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.delVisibleFive = false;
                    this.$message({ type: 'success', message: '删除成功!'});
                    this.getCustomerStatu();
                }
            });
        },

        //商机阶段配置
        getStage(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/Stage`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.StageOption = data;
                }
            });
        },
        toNewStage(){
            this.addVisibleSix = true;
            this.addForm = {
                key: "",
                name: "",
                color: "",
            };
        },
        newStageSubmit(){
            if(!this.addForm.name){
                this.$message({ message: '请输入商机阶段名称', type: 'warning'});
                return false;
            }
            this.$axios.post(`${this.$customerUrl}/v1/Config/Stage`,this.addForm).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.addVisibleSix = false;
                    this.$message({ message: '新建成功', type: 'success'});
                    this.getStage();
                }
            });
        },
        toEditStage(row){
            this.editVisibleSix = true;
            this.editForm = {...row};
        },
        editStageSubmit(){
            if(!this.editForm.name){
                this.$message({ message: '请输入商机阶段名称', type: 'warning'});
                return false;
            }
            this.$axios.post(`${this.$customerUrl}/v1/Config/Stage`,this.editForm).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.editVisibleSix = false;
                    this.$message({ message: '编辑成功', type: 'success'});
                    this.getStage();
                }
            });
        },
        toDelStage(row){
            this.delVisibleSix = true;
            this.delKey = row.key;
            this.delName = row.name;
            this.transferKey = "";
        },
        delStageSubmit(){
            if(!this.transferKey){
                this.$message({ message: '请选择转移的对象', type: 'warning'});
                return false;
            }
            this.$axios.delete(`${this.$customerUrl}/v1/Config/Stage?key=${this.delKey}&transferKey=${this.transferKey}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.delVisibleSix = false;
                    this.$message({ type: 'success', message: '删除成功!'});
                    this.getStage();
                }
            });
        },

        //客户回收站
        getRecycle(){
            this.$axios.get(`${this.$customerUrl}/Company/FindTrashDepot?customerName=${this.customerName}&pageIndex=${this.pageIndex}&pageSize=10`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.recycleList = data.datas;
                    this.totalItems = data.totalItems;
                }
            });
        },
        pageChange(page){
            this.pageIndex = page;
            this.getRecycle();
        },

        //撞单风险提示
        setCollisionRisk(value){
            this.$axios.get(`${this.$customerUrl}/v1/Config/SetCollisionRisk?isShow=${value}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.sysConfig.isShowCollisionRisk = value;
                    localStorage.setItem('sysConfig',JSON.stringify(this.sysConfig));
                    this.$message({ message: value ? '撞单风险提示已开启' : '撞单风险提示已关闭' , type: 'success'});
                }
            });
        },

        //保存公司系统配置
        setCompanyConfig(){
            // console.log(this.comConfig);
            this.$axios.post(`${this.$customerUrl}/v1/Config/OtherSet`,this.comConfig).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    localStorage.setItem('comConfig',JSON.stringify(this.comConfig));
                    this.$message({ message: "设置成功", type: 'success'});
                }
            });
        },
        //客户字段是否必填设置
        saveCustomerField(){
            this.$axios.post(`${this.$customerUrl}/v1/Config/SetCustomerFieldSettings`,this.comConfig.customerFieldSettings).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    localStorage.setItem('comConfig',JSON.stringify(this.comConfig));
                    this.$message({ message: "设置成功", type: 'success'});
                }
            });
        },

    },
    mounted() {
        let activeName = this.$route.query.activeName;
        if(activeName){
            this.activeName = activeName;
        }
        this.isAdmin = JSON.parse(localStorage.userInfo).isAdmin;

        this.getPrivateGroup();     //私海分组配置
        this.getPublicGroup();      //公海分组配置
        this.getCustomerTag();      //客户标签配置
        this.getCustomerSource();   //公司来源配置
        this.getCustomerStatu();    //公司状态配置
        // this.getStage();            //商机阶段配置

        // this.getRecycle();          //客户回收站
    },
};
</script>

<style lang="scss" scoped>
.tabCard{
    background: #FFFFFF;
    padding: 0 20px 20px;
    overflow: auto;
    height: calc(100vh - 100px);
}

.el-tag--mini{
    line-height: 18px;
}

.nameStyle{
    .oneItem{
        margin-left: 20px;
        height: 20px;
        font-size: 12px;
        padding: 0 5px;
        color: #F96B0D;
        border: 1px solid #F96B0D;
        border-radius: 4px;
    }
    .hoverShow{
        margin-left: 20px;
        cursor: pointer;
        display: none;
    }
    &:hover .hoverShow{
        display: inline-block;
    }
}
</style>