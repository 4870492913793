<template>
<div>
    <div class="updateLog">
        <div class="headline">更新日志</div>
        <div style="margin-bottom: 16px;">
            <el-radio-group v-model="chooseValue" size="small" @change="yearChange" >
                <el-radio-button v-for="(item,index) in allLogList" :key="index" :label="item.year"></el-radio-button>
            </el-radio-group>
        </div>
        <el-timeline class="timeline">
            <el-timeline-item type="primary" placement="top" v-for="item in showLogList" :key="item.month" hide-timestamp>
                <div class="monthTitle">{{item.month}}</div>
                <el-card class="content">
                    <div v-for="ele in item.logDays" :key="ele.date" style="margin-bottom: 8px;">
                        <!-- <h4>{{ele.date}}</h4> -->
                        <div v-html="ele.updateContent"></div>  
                    </div>
                </el-card>  
            </el-timeline-item>
        </el-timeline>
    </div>

    <el-button v-if="publishAuthority" type="primary" size="small" @click="openDialog" style="margin:20px 0;">发布更新内容</el-button>

    <div class="reviewCard" v-if="publishAuthority">
        <div class="headline">待审核内容</div>
        <el-table :data="reviewData" border style="width: 100%;" size="small">
            <el-table-column prop="date" label="更新时间" width="200px"></el-table-column>
            <el-table-column label="更新内容">
                <template slot-scope="scope">
                    <div v-html="scope.row.updateContent"></div>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="220">
                <template slot-scope="scope">
                    <el-button @click="onPass(scope.row)" type="success" size="small">通过</el-button>
                    <el-button @click="toModify(scope.row)" type="primary" size="small">修改</el-button>
                    <el-button @click="toDel(scope.row)" type="danger" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>

    <el-dialog title="发布更新内容" :visible.sync="dialogVisible" width="820px" top="10vh">
        <el-date-picker v-model="submitForm.updateTime" type="date" value-format="yyyy-MM-dd"></el-date-picker>
        <Editor v-model="submitForm.updateContent" placeholder="更新内容" style="margin-top:20px;" />
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="dialogVisible = false;">取 消</el-button>
            <el-button size="small" type="primary" @click="onSubmit" :loading="btnLoading">确 认</el-button>
        </span>
    </el-dialog>

    <el-dialog title="修改更新内容" :visible.sync="modifyVisible" width="820px" top="10vh">
        <el-date-picker v-model="modifyForm.updateTime" type="date" value-format="yyyy-MM-dd"></el-date-picker>
        <Editor v-model="modifyForm.updateContent" placeholder="更新内容" style="margin-top:20px;" />
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="finishModify(false)" :loading="btnLoading">只修改</el-button>
            <el-button size="small" type="primary" @click="finishModify(true)" :loading="btnLoading">修改并通过</el-button>
        </span>
    </el-dialog>

</div>
</template>

<script>
import Editor from "@/components/Editor/Editor.vue";
import dayjs from "dayjs";
export default {
    components: { Editor },
    data() {
        return {
            publishAuthority: false,
            //更新日志
            chooseValue: "2025年",
            allLogList: [],
            showLogList: [],
            //发布更新内容
            dialogVisible: false,
            btnLoading: false,
            submitForm: {
                key: "",
                isShow: false,
                updateTime: dayjs().format('YYYY-MM-DD'),
                updateContent: "",
            },
            //审核更新内容
            reviewData: [],
            //修改更新内容
            modifyVisible: false,
            modifyForm: {},
        };
    },
    methods: {
        yearChange(value){
            this.chooseValue = value;
            this.showLogList = this.allLogList.filter(item => item.year == this.chooseValue)[0].logMonths;
        },
        //获取系统更新日志
        getSystemUpdateLog(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/GetSystemUpdateLog`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.allLogList = data;
                    this.yearChange("2025年");
                }
            })
        },

        //发布更新内容
        openDialog(){
            this.dialogVisible = true;
            this.submitForm = {
                key: "",
                isShow: false,
                updateTime: dayjs().format('YYYY-MM-DD'),
                updateContent: "",
            }
        },
        onSubmit(){
            if(!this.submitForm.updateContent){
                this.$message({ message: '请填写更新内容', type: 'warning'});
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$customerUrl}/v1/Config/SaveSystemUpdateLog`, this.submitForm).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: '提交成功', type: 'success'});
                    this.dialogVisible = false;
                    this.getReviewed();
                }
            })
        },
        //审核更新内容
        getReviewed(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/GetTobeReleasedSystemUpdateLog`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.reviewData = data;
                }
            })
        },
        onPass(row){
            this.$axios.get(`${this.$customerUrl}/v1/Config/ReleaseSystemUpdateLog?key=${row.key}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: '更新内容已通过审核!', type: 'success'});
                    this.getReviewed();
                    this.getSystemUpdateLog();
                }
            })
        },

        //修改更新内容
        toModify(row){
            this.modifyVisible = true;
            this.modifyForm = {
                key: row.key,
                isShow: false,
                updateTime: row.date,
                updateContent: row.updateContent,
            }
        },
        finishModify(type){
            if(!this.modifyForm.updateContent){
                this.$message({ message: '请填写更新内容', type: 'warning'});
                return false;
            }
            this.modifyForm.isShow = type;
            this.btnLoading = true;
            this.$axios.post(`${this.$customerUrl}/v1/Config/SaveSystemUpdateLog`, this.modifyForm).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: '修改成功', type: 'success'});
                    this.modifyVisible = false;
                    this.getReviewed();
                    if(type){
                        this.getSystemUpdateLog();
                    }
                }
            })
        },
        //删除
        toDel(row){
            this.$axios.get(`${this.$customerUrl}/v1/Config/DeleteSystemUpdateLog?key=${row.key}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: '删除成功', type: 'success'});
                    this.getReviewed();
                }
            })
        },

    },
    mounted() {
        this.getSystemUpdateLog();  //获取系统更新日志
        let userInfo = JSON.parse(localStorage.userInfo);
        if(userInfo.companyKey == "52b9b01c3c67494c8114f35e7b912779"){
            this.publishAuthority = true;
            this.getReviewed();
        }
    },
};
</script>

<style lang="scss" scoped>
.updateLog{
    background-color: #fff;
    padding: 10px 20px;
    .timeline{
        .monthTitle{
            height: 32px;
            line-height: 32px;
            font-size: 16px;
            font-weight: bold;
        }
        .content{
            h4,p{
                line-height: 24px;
            }
        }
        /deep/.el-card__body {
            padding: 10px;
        }
    }
    .el-timeline-item{
        padding-bottom: 12px;
    }
}
.headline{
    font-size: 18px;
    color: #3a3a3a;
    font-weight: bold;
    margin-bottom: 10px;
}
.reviewCard{
    background-color: #fff;
    padding: 10px 20px;
}
</style>