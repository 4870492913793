<template>
    <div class="login" v-loading="pageLoading">
        <img class="bg3" src="@/assets/img/bg3.png" alt="" />
        <img class="logo" src="@/assets/img/logo.png" alt="" />
        <div class="copyright">©Copyright 2022 上海企芯信息科技有限公司保留所有权利 <span @click="openBlank('https://beian.miit.gov.cn/')">沪ICP备2022000736号</span></div>

        <div class="middleBox">
            <div class="leftBox">
                <div class="p1">欢迎登录</div>
                <div class="p1">一</div>
                <div class="p2">企芯CRM客户管理</div>
                <div class="p2">后台系统</div>
                <div class="p3">科技智能一体化服务平台</div>
                <div class="p4" style="cursor: pointer" @click="openBlank('https://www.qixinerp.com')">联系我们</div>
                <img class="bg4" src="@/assets/img/bg4.png" alt="" />
            </div>
            <div class="rightBox">
                <div v-show="loginType == 1 || loginType == 3">
                    <div class="header">
                        <div class="txt" v-show="loginType == 1">手机号登录</div>
                        <div class="txt" v-show="loginType == 3">账密登录</div>
                        <img @click="toSmLogin" src="@/assets/img/toSm1.png" alt="" class="pointer" style="float:right;" />
                        <img @click="toSmLogin" src="@/assets/img/toSm2.png" alt="" class="pointer" style="float:right;margin-right:10px;" />
                    </div>
                    <el-form v-show="loginType == 1" class="loginForm" ref="loginFormOne" :model="loginForm" :rules="rulesOne" style="margin-top:30px;">
                        <el-form-item prop="phone">
                            <img class="iconImg" src="@/assets/img/icon-account2.png" alt="" />
                            <el-input v-model="loginForm.phone" name="phone" autocomplete="on" placeholder="手机号" clearable @keyup.enter.native="onLoginOne"></el-input>
                        </el-form-item>
                        <el-form-item prop="verifyCode" style="display: flex;">
                            <img class="iconImg" src="@/assets/img/icon-verifyCode2.png" alt="" />
                            <el-input v-model="loginForm.verifyCode" name="verifyCode" placeholder="验证码" clearable @keyup.enter.native="onLoginOne"></el-input>
                            <el-button type="primary" round class="getCode" @click="getPhoneCode" :disabled="btnHtml != '获取验证码'">{{btnHtml}}</el-button>
                        </el-form-item>
                        <el-form-item>
                            <!-- <el-checkbox v-model="isKeep">记住密码</el-checkbox> -->
                            <div style="color: var(--themeColor);float:right;cursor: pointer;" @click="loginType = 3">账密登录</div>
                        </el-form-item>
                        <el-form-item>
                            <el-button class="subBtn" type="primary" @click="onLoginOne" :loading="btnLoading">登 录</el-button>
                        </el-form-item>
                    </el-form>
                    <el-form v-show="loginType == 3" class="loginForm" ref="loginFormTwo" :model="loginForm" :rules="rulesTwo" style="margin-top:30px;">
                        <el-form-item prop="account">
                            <img class="iconImg" src="@/assets/img/icon-account2.png" alt="" />
                            <el-input v-model="loginForm.account" name="account" autocomplete="on" placeholder="账号/邮箱" clearable @keyup.enter.native="onLoginTwo"></el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <img class="iconImg" src="@/assets/img/icon-password2.png" alt="" />
                            <el-input v-model="loginForm.password" name="password" autocomplete="on" placeholder="密码" clearable @keyup.enter.native="onLoginTwo" show-password></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-checkbox v-model="isKeep">记住密码</el-checkbox>
                            <div style="color: var(--themeColor);float:right;cursor: pointer;" @click="loginType = 1">手机号登录</div>
                        </el-form-item>
                        <el-form-item>
                            <el-button class="subBtn" type="primary" @click="onLoginTwo" :loading="btnLoading">登 录</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div v-show="loginType == 2">
                    <div class="header">
                        <div class="txt">微信扫码</div>
                        <img @click="toAcLogin" src="@/assets/img/toAc1.png" alt="" class="pointer" style="float:right;" />
                        <img @click="toAcLogin" src="@/assets/img/toAc2.png" alt="" class="pointer" style="float:right;margin-right:10px;" />
                    </div>
                    <div style="text-align: center;margin-top:10px;" v-loading="codeLoading">
                        <img :src="imgCodeSrc" alt="" style="width:300px;height:300px;" />
                        <p>微信扫一扫登录</p>
                    </div>
                </div>
                <div class="clause">
                    <span>登录即表明您同意</span>
                    <span class="toAgreement" @click="toAgreement(1)">《服务协议》</span>和
                    <span class="toAgreement" @click="toAgreement(2)">《隐私条款》</span>
                    <span>version:{{version}}</span>
                </div>
                <div style="display: flex;justify-content: space-between;">
                    <el-link :underline="false" style="color: var(--themeColor);" href="https://vip.dqxx.com.cn/Erp/Login" target="_blank">跨境搜授权</el-link>
                    <div>
                        <div class="register" @click="toRegister">注册账号</div>
                        <div class="foeget" @click="toForgotPwd">忘记密码?</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
let codetimer = null;
import config from '../../package.json'
import { Encrypt , Decrypt } from '../assets/js/secret.js'
export default {
    data() {
        return {
            pageLoading:false,
            version: config.version,    //版本号
            loginType: 2,            //手机号登录(1) 微信扫码(2) 账密登录(3)
            
            btnLoading: false,
            loginForm:{
                account: "",
                password: "",
                phone: "",
                verifyCode: "",
            },
            rulesOne: {
                phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
                verifyCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
            },
            rulesTwo: {
                account: [{ required: true, message: "请输入账号/邮箱", trigger: "blur" }],
                password: [{ required: true, message: "请输入密码", trigger: "blur" }],
            },
            //公众号
            ticket: "",
            imgCodeSrc: "",
            codeLoading: false,
            //是否记住密码
            isKeep: false,
            btnHtml: "获取验证码",
        };
    },
    methods: {
        openBlank(url){
            window.open(url, '_blank')
        },
        //点击微信扫码
        toSmLogin(){
            this.loginType = 2;
            this.codeLoading = true;
            this.$axios.get(`${this.$authUrl}/WX/Create`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.ticket = data.ticket;
                    this.imgCodeSrc = `${this.$authUrl}/WX/GetQrCode?ticket=${this.ticket}`;
                    this.codeLoading = false;
                    let num = 0;
                    codetimer = setInterval(() => {
                        num++
                        this.CheckQrLogin();
                        if(num >= 60){
                            clearInterval(codetimer);
                        }
                    }, 1000);
                }
            });
        },
        CheckQrLogin(){
            this.$axios.get(`${this.$authUrl}/WX/CheckQrLogin?ticket=${this.ticket}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    clearInterval(codetimer);
                    // 需要跳转注册
                    if(data.isRegister){
                        this.$notify({ message: "账号不存在",position: "top-right",duration: 2000,type: 'warning' })
                        return false;
                        // this.$router.push(`/register?openid=${data.openid}`);
                    }
                    // 已绑定,直接登录
                    if(data.isBind){
                        this.$message({message: "登录成功",type: 'success'});
                        localStorage.setItem("token", data.token);
                        this.$cookieStore.setCookie("token", data.token, 24*7);
                        this.$router.push('/homepage/workbench');

                        const {frommail,key} = this.$route.query;
                        if(frommail){
                            this.$axios({
                                method:'GET',
                                url:this.$authUrl+'/api/Token/SaveToken',
                                params:{
                                    key,
                                    token: data.token,
                                }
                            })
                        }
                    }
                }
            });
        },

        //点击手机号登录
        toAcLogin(){
            this.loginType = 1;
            clearInterval(codetimer);
        },
        
        //获取手机验证码
        getPhoneCode() {
            if (!this.loginForm.phone) {
                this.$message({ message: "请输入手机号获取验证码", type: "warning",});
                return false;
            }
            this.$axios.post(`${this.$authUrl}/api/Account/SendSms`,{
                phone: this.loginForm.phone,
            }).then((res) => {
                if(res === true){
                    this.$message({message: "手机验证码已发送",type: 'success'});
                    var num = 60;
                    var timer = setInterval(() => {
                        if(num == 0){
                            clearInterval(timer)
                            this.btnHtml = "获取验证码"
                        }else{
                            this.btnHtml = `倒计时${num}s`
                            num--
                        }
                    }, 1000);
                }
            });
        },
        // 手机号登录
        onLoginOne(){
            const {frommail,key} = this.$route.query;
            this.$refs["loginFormOne"].validate((valid) => {
                if (valid) {
                    let {phone,verifyCode} = this.loginForm;
                    this.btnLoading = true;
                    this.$axios.get(`${this.$authUrl}/api/Token/PhoneLogin?phone=${phone}&code=${verifyCode}`).then((res) => {
                        const { isSuccess, data } = res;
                        this.btnLoading = false;
                        if (isSuccess) {
                            this.$message({message: "登录成功",type: 'success'});
                            localStorage.setItem("token", data.token);
                            this.$cookieStore.setCookie("token", data.token, 24*7);

                            if(frommail){
                                this.$axios({
                                    method:'GET',
                                    url:this.$authUrl+'/api/Token/SaveToken',
                                    params:{
                                        key,
                                        token: data.token,
                                    }
                                })
                            }

                            this.$axios.get(`${this.$authUrl}/api/User/Get`).then((res) => {
                                const { isSuccess, data } = res;
                                if (isSuccess) {
                                    if(data.isFirstLogin){
                                        //第一次登录进入引导页配置
                                        if(data.isAdmin){
                                            this.$router.push('/homepage/adminConfig');
                                        }else{
                                            this.$router.push('/homepage/personalConfig');
                                        }
                                    }else if(data.isFinance){
                                        //财务角色
                                        this.$router.push('/finance/performanceManage');
                                    }else{
                                        this.$router.push('/homepage/workbench');
                                    }
                                }
                            });
                        }
                    });
                }
            })
        },

        // 账密登录
        onLoginTwo(){
            const {frommail,key} = this.$route.query;
            this.$refs["loginFormTwo"].validate((valid) => {
                if (valid) {
                    let {account,password} = this.loginForm;
                    this.btnLoading = true;
                    this.$axios.get(`${this.$authUrl}/api/Token/Login?account=${account}&pwd=${password}&isCache=false`).then((res) => {
                        const { isSuccess, data } = res;
                        this.btnLoading = false;
                        if (isSuccess) {
                            //是否记住密码
                            if(this.isKeep){
                                let keepObj = { 
                                    isKeep: this.isKeep,
                                    account: Encrypt(account),
                                    password: Encrypt(password),
                                }
                                localStorage.setItem('keepObj',JSON.stringify(keepObj));
                            }else{
                                localStorage.removeItem('keepObj');
                            }
                            this.$message({message: "登录成功",type: 'success'});
                            localStorage.setItem("token", data.token);
                            this.$cookieStore.setCookie("token", data.token, 24*7);
                      
                            if(frommail){
                                this.$axios({
                                    method:'GET',
                                    url:this.$authUrl+'/api/Token/SaveToken',
                                    params:{
                                        key,
                                        token: data.token,
                                    }
                                })
                            }

                            this.$axios.get(`${this.$authUrl}/api/User/Get`).then((res) => {
                                const { isSuccess, data } = res;
                                if (isSuccess) {
                                    if(data.isFirstLogin){
                                        //第一次登录进入引导页配置
                                        if(data.isAdmin){
                                            this.$router.push('/homepage/adminConfig');
                                        }else{
                                            this.$router.push('/homepage/personalConfig');
                                        }
                                    }else{
                                        this.$router.push('/homepage/workbench');
                                    }
                                }
                            });
                        }
                    });
                }
            })
        },
        // 忘记密码
        toForgotPwd(){
            this.$router.push('/forgotPwd');
        },
        // 注册账号
        toRegister(){
            this.$router.push('/register');
        },

        toAgreement(type){
            if(type == 1){
                let routeData = this.$router.resolve({
                    path: "/ServiceAgreementCN",
                });
                window.open(routeData.href, "_blank");
            }
            if(type == 2){
                let routeData = this.$router.resolve({
                    path: "/PrivacyPolicyCN",
                });
                window.open(routeData.href, "_blank");
            }
        }
    },
    mounted() {
        let keepObj = localStorage.keepObj ? JSON.parse(localStorage.keepObj): {};
        if(keepObj.isKeep){
            this.isKeep = keepObj.isKeep;
            this.loginForm.account = Decrypt(keepObj.account);
            this.loginForm.password = Decrypt(keepObj.password);
        }
        this.toSmLogin();
        // localStorage.removeItem("isCloseWx");
    },
    async beforeMount(){
        const {frommail,key} = this.$route.query;
        if(frommail){
            const token = localStorage.getItem("token");
            if(token){
               this.pageLoading = true;
               const data=await(await fetch(`${this.$customerUrl}/Report/GetFollowRecord`,{
                    headers:{
                        Authorization:'Bearer ' + token
                    }
                })).json()
                if(data.isSuccess){
                    this.$axios({
                        method:'GET',
                        url:this.$authUrl+'/api/Token/SaveToken',
                        params:{
                            key,
                            token
                        }
                    })
                    this.$router.push('/homepage/workbench');
                    this.pageLoading = false;
                }else{
                    this.pageLoading = false;
                }
            }
            return
        }
        const ukey = this.$route.query.ukey;
        const companykey = this.$route.query.companykey;
        if(ukey){
            this.$axios.get(`${this.$authUrl}/Callback/VipLogin?ukey=${ukey}`).then((res) => {
                const { isSuccess, data } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    localStorage.setItem("token", data.token);
                    this.$cookieStore.setCookie("token", data.token, 24*7);
                    if(companykey){
                        this.$router.push(`/customerManage/customerDetailsPrivate?customerKey=${companykey}`);
                    }else{
                        this.$router.push('/homepage/workbench');
                    }
                }
            });
        }else{
            //存在token 直接进入首页
            let token = localStorage.getItem("token");
            if(token){
                this.$router.push('/homepage/workbench');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.login{
    width: 100vw;
    height: 100vh;
    .bg3{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }
    .logo{
        position: fixed;
        top: 62px;
        left: 72px;
        z-index: 20;
    }
    .copyright{
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 30;
        width: 100%;
        height: 48px;
        line-height: 48px;
        font-size: 20px;
        color: #FFFFFF;
        opacity: 0.67;
        text-align: center;
    }

    .middleBox{
        position: fixed;
        z-index: 40;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 1200px;
        height: 540px;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        border-radius: 16px;
        display: flex;
    }
    .leftBox{
        width: 700px;
        border-right: 1px solid #e6e6e6;
        box-sizing: border-box;
        padding: 60px 70px;
        position: relative;
        .p1{
            font-size: 30px;
            color: $themeColor;
        }
        .p2{
            font-size: 40px;
            font-weight: bold;
            color: $themeColor;
        }
        .p3{
            margin-top: 40px;
            font-size: 20px;
            color: $themeColor;
        }
        .p4{
            margin-top: 100px;
            width: 120px;
            text-align: center;
            font-size: 24px;
            line-height: 40px;
            color: $themeColor;
            border: 1px solid $themeColor;
            border-radius: 4px;
        }
        .bg4{
            position: absolute;
            bottom: -70px;
            right: -30px;
        }
    }
    .rightBox{
        width: 500px;
        box-sizing: border-box;
        padding: 60px 20px 0 60px;
        .header{
            overflow: hidden;
            .txt{
                font-size: 30px;
                color: $themeColor;
                float: left;
            }
        }
        .loginForm{
            width: 400px;
            .iconImg{
                position: absolute;
                top: 15px;
                left: 30px;
                z-index: 60;
            }
            .getCode{
                position: absolute;
                right: 4px;
                top: 4px;
            }
        }
        p{
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            color: #C7C7C7;
        }
        // 修改input框样式
        /deep/.el-input__inner {
            width: 400px;
            height: 48px;
            line-height: 48px;
            border-radius: 36px;
            font-size: 18px;
            padding-left: 60px;
            background: rgba($color: #c4c4c4, $alpha: 0.13);
            border: none;
            color: #333;
            font-weight: bold;
        }
        .subBtn{
            width: 100%;
            height: 48px;
            border-radius: 32px;
            background-color: $themeColor;
            border-color: $themeColor;
            box-shadow: 0px 5px 12px rgba(37, 88, 191, 0.1);
            font-size: 20px;
            color: #FFFFFF;
            &:hover{
                opacity: 0.8;
            }
        }

        .clause{
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            color: #C7C7C7;
            .toAgreement{
                cursor: pointer;
                color: $themeColor;
            }
        }
        .foeget{
            display: inline-block;
            width: 80px;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            color: #C7C7C7;
            cursor: pointer;
        }
        .register{
            display: inline-block;
            width: 80px;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            color: var(--themeColor);
            cursor: pointer;
        }
    }
}
</style>
